import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/index.vue')
  },
  {
    path: '/layout',
    name: 'layout',
    component: () => import('../views/layout/index.vue'),
    redirect: '/usermanage',
    children: [
      {
        path: '/usermanage',
        name: 'UserManage',
        component: () => import('../views/UserManage/index.vue'),
        meta: { title: '用户管理' }
      },
      {
        path: '/stationmanage',
        name: 'StationManage',
        component: () => import('../views/StationManage/index.vue'),
        meta: { title: '站点管理' }
      }
    ]
  }
]
const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
// 全局前置路由守卫，表示在每次切换路由之前调用，针对所有的路由
router.beforeEach((to, from, next)=>{                         // to表示去哪个路由，from表示来自哪个路由，next表示放行
  const token = localStorage.getItem('token')
  // 可加判断条件进行放行
  if(to.path !== '/login' && !token && to.path !== '/400'){                                 // 判断要去的路由条件
    next('/login')
  }
  else{
    next()
  }
})

export default router
