import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'normalize.css'
import plugins from './plugins' // plugins
import './assets/icons' // icon
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
// 分页组件
import Pagination from "@/components/Pagination";
// 字典标签组件
import DictTag from '@/components/DictTag'
import { parseTime, resetForm, addDateRange, selectDictLabel, selectDictLabels, handleTree } from "@/utils/ruoyi";
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  ak: `plVmyPgGe6hzpAFAmTFEx4P9AZPcLtt`
})

// 全局方法挂载
Vue.prototype.addDateRange = addDateRange
Vue.prototype.parseTime = parseTime

// 全局组件挂载
Vue.component('Pagination', Pagination)
Vue.component('DictTag', DictTag)


Vue.use(Element)
Vue.use(plugins)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
